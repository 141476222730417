import '@b2c/core/app/service/domain/state.js'
(function () {
  'use strict';
  const directive = { name: 'rabonaLinkForOs' };
  controller.$inject = ['$state', 'deferredPrompt'];

  function controller(_state) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);

      const osValue = () => {
        const ua = navigator.userAgent;
        if (/android/i.test(ua)) {
          return config.android;
        }
        if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
          return config.ios;
        }
        return null;
      };

      element.on('click', () => {
        const value = osValue();
        if (value?.url) window.open(value.url);
        if (value?.alias) _state.go(value.alias);
        console.error('Directive doesn`t have os / url / alias' );
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
